import React, { useEffect, useState } from "react";
import Button from "../../widgets/Button";
import { useTranslation } from "react-i18next";
import { handleToast } from "../../utils/handleToast";
import toast from "react-hot-toast";
import {
  useAddUserCommissionMutation,
  useGetUserCommissionQuery,
} from "../../../redux/commissionApi";
import {
  isNegative,
  ValidateInputFields,
} from "../../validations/ValidateInputFields";

const UserCommission = ({
  targetUserId,
  isEdit,
  isNew,
  onClose,
  editData,
  type,
}) => {
  const { t } = useTranslation();
  const { data, refetch, isLoading } = useGetUserCommissionQuery({
    userId: targetUserId,
    segment: type,
  });
  console.log(data, "data");
  const [addUserCommission, { isLoading: addingCommission }] =
    useAddUserCommissionMutation();
  const [formData, setFormData] = useState({
    commissionType: { value: "Flat", required: true },
    commissionValue: { value: "", required: true },
  });

  const [errors, setErrors] = useState({
    commissionValue: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });

    const validationErrors = [];
    const validationError = ValidateInputFields(
      name,
      value,
      formData[name].required
    );

    console.log(validationError, "validationError");

    if (validationError) validationErrors.push(validationError);

    const negativeValidation = isNegative(value);
    if (negativeValidation) validationErrors.push(negativeValidation);

    setErrors((prevError) => ({
      ...prevError,
      [name]: validationErrors.length ? validationErrors.join(", ") : null,
    }));
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const { commission_type, commission_value } = data[0];
      setFormData((prev) => {
        return {
          commissionType: { value: commission_type, required: true },
          commissionValue: { value: commission_value, required: true },
        };
      });
    }
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = Object.fromEntries(
      Object.entries(formData).map(([key, { value }]) => [key, value])
    );

    for (let key in formData) {
      const validationError = ValidateInputFields(
        key,
        formData[key].value,
        formData[key].required
      );
      setErrors((prevError) => ({
        ...prevError,
        [key]: validationError,
      }));

      if (validationError) return;
    }

    console.log(values, "values;");

    const mutationResult = isEdit
      ? await addUserCommission({
          ...values,
          userId: targetUserId,
          segment: type,
        })
      : await addUserCommission({
          ...values,
          userId: targetUserId,
          segment: type,
        });

    handleToast(t, mutationResult);
    if (mutationResult?.data?.status) {
      refetch();
    }
  };

  console.log(formData, "forr");

  return (
    <div>
      <div className="form-group">
        <label className="fw-bold">{t("Commission Type")}</label>
        <select
          className="form-control"
          name="commissionType"
          value={formData.commissionType.value}
          onChange={handleChange}
        >
          <option value="Flat">Flat</option>
          <option value="Percentage">Percentage</option>
        </select>
      </div>

      <div className="form-group mt-3">
        <label className="fw-bold">{t("Commission Value")}</label>
        <input
          type="number"
          className="form-control"
          placeholder={t("Enter Commission Value")}
          name="commissionValue"
          value={formData.commissionValue.value}
          onChange={handleChange}
        />
        {errors.commissionValue && (
          <span style={{ color: "red" }}>{t(errors.commissionValue)}</span>
        )}
      </div>

      <Button
        className="btn flex justify-center items-center disabled:opacity-50 disabled:cursor-not-allowed shadow-sm focus:outline-none hover:opacity-80 btn-primary  btn btn1"
        onClick={handleSubmit}
        isLoading={addingCommission}
      >
        {t("Submit")}
      </Button>
    </div>
  );
};

export default UserCommission;
