import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useTranslation } from "react-i18next";
// import Profile from "./Profile";
import {
  CryptoCommission,
  StockCommission,
  FuturesCommission,
} from "../commission";
import { CryptoSpreads } from "../spreadsPopup";
import AssetTabs from "./AssetTabs";
import { CryptoRiskInfo, FuturesRiskInfo, StockRiskInfo,CryptoFuturesRiskInfo} from "../riskInfo";
import { Crypto, Forex, Equity } from "../widgetsPermissions";
import { Leverage } from "./Leverage";
import { ChangePassword } from "./ChangePassword";
import AssetBlockTabs from "./AssetBlockTabs";
import FxEqSpread from "../spreadsPopup/FxEqSpreads";
import UserCommission from "../commission/UserCommission";

export const UserPopupTabs = ({
  isEdit,
  editData,
  isNew,
  refetch,
  onClose,
  getNewUser,
  Profile,
  tabIndex,
  handleTabIndex,
}) => {
  const { t } = useTranslation();

  return (
    <div className="parent-tabs">
      <Tabs
        className="level-1-panels row"
        selectedIndex={tabIndex}
        onSelect={(index) => handleTabIndex(index)}
      >
        <div className="col-md-3">
          <TabList className="vertical-tabs left-tabs">
            <Tab>{t("Profile")}</Tab>
            {/* <Tab disabled={isNew}>{t("Asset Permissions")}</Tab> */}
            {isEdit && <Tab disabled={isNew}>{t("Commission")}</Tab>}
            {isEdit && editData.role === "SubIB" && (
              <Tab disabled={isNew}>{t("Spreads")}</Tab>
            )}
            <Tab disabled={isNew}>{t("Risk Info")}</Tab>

            {isEdit &&
              (editData.role === "Informatic Broker (IB)" ||
                editData.role === "SubIB") && (
                <Tab disabled={isNew}>{t("Leverage")}</Tab>
              )}
            {isEdit && <Tab disabled={isNew}>{t("Change Password")}</Tab>}
            {isEdit &&
              (editData.role === "Informatic Broker (IB)" ||
                editData.role === "SubIB") && (
                <Tab disabled={isNew}>{t("Widget Permissions")}</Tab>
              )}
            {isEdit &&
              (editData.role === "Informatic Broker (IB)" ||
                editData.role === "SubIB") && (
                <Tab disabled={isNew}>{t("Asset Block")}</Tab>
              )}
          </TabList>
        </div>
        <div className="col-md-9 scrool_right_data">
          <div>
            <TabPanel>
              {isEdit ? (
                <Profile
                  onClose={onClose}
                  refetch={refetch}
                  isEdit={isEdit}
                  data={editData}
                  getNewUser={getNewUser}
                />
              ) : (
                <Profile
                  onClose={onClose}
                  refetch={refetch}
                  isNew={isNew}
                  getNewUser={getNewUser}
                />
              )}
            </TabPanel>
            {/* <TabPanel>
              <AssetTabs isEdit={isEdit} editData={editData} />
            </TabPanel> */}
            {isEdit && editData.role !== "SubIB" ? (
              <TabPanel>
                <Tabs>
                  <TabList>
                    <Tab>{t("Crypto Commission")}</Tab>
                    <Tab>{t("Forex Commission")}</Tab>
                    <Tab>{t("Equity Commission")}</Tab>
                  </TabList>
                  <TabPanel>
                    {isEdit && (
                      <CryptoCommission
                        isEdit={isEdit}
                        isNew={isNew}
                        targetUserId={editData.id}
                        refetch={refetch}
                        onClose={onClose}
                        editData={editData}
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {isEdit && (
                      <FuturesCommission
                        isEdit={isEdit}
                        isNew={isNew}
                        targetUserId={editData.id}
                        refetch={refetch}
                        onClose={onClose}
                        editData={editData}
                        type="forex"
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {isEdit && (
                      <FuturesCommission
                        isEdit={isEdit}
                        isNew={isNew}
                        targetUserId={editData.id}
                        refetch={refetch}
                        onClose={onClose}
                        editData={editData}
                        type="equity"
                      />
                    )}
                  </TabPanel>
                </Tabs>
              </TabPanel>
            ) : (
              <TabPanel>
                <Tabs>
                  <TabList>
                    <Tab>{t("Crypto Commission")}</Tab>
                    <Tab>{t("Forex Commission")}</Tab>
                    <Tab>{t("Equity Commission")}</Tab>
                  </TabList>
                  <TabPanel>
                    {isEdit && (
                      <UserCommission
                        isEdit={isEdit}
                        isNew={isNew}
                        targetUserId={editData.id}
                        refetch={refetch}
                        onClose={onClose}
                        editData={editData}
                        type="crypto"
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {isEdit && (
                      <UserCommission
                        isEdit={isEdit}
                        isNew={isNew}
                        targetUserId={editData.id}
                        refetch={refetch}
                        onClose={onClose}
                        editData={editData}
                        type="forex"
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {isEdit && (
                      <UserCommission
                        isEdit={isEdit}
                        isNew={isNew}
                        targetUserId={editData.id}
                        refetch={refetch}
                        onClose={onClose}
                        editData={editData}
                        type="equity"
                      />
                    )}
                  </TabPanel>
                </Tabs>
              </TabPanel>
            )}
            {isEdit && editData.role === "SubIB" && (
              <TabPanel>
                <Tabs>
                  <TabList>
                    <Tab>{t("Crypto Spreads")}</Tab>
                    <Tab>{t("Forex Spreads")}</Tab>
                    <Tab>{t("Equity Spreads")}</Tab>
                  </TabList>
                  <TabPanel>
                    {isEdit && (
                      <CryptoSpreads
                        isEdit={isEdit}
                        isNew={isNew}
                        targetUserId={editData.id}
                        refetch={refetch}
                        onClose={onClose}
                        editData={editData}
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {isEdit && (
                      <FxEqSpread
                        isEdit={isEdit}
                        isNew={isNew}
                        targetUserId={editData.id}
                        refetch={refetch}
                        onClose={onClose}
                        editData={editData}
                        type="forex"
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {isEdit && (
                      <FxEqSpread
                        isEdit={isEdit}
                        isNew={isNew}
                        targetUserId={editData.id}
                        refetch={refetch}
                        onClose={onClose}
                        editData={editData}
                        type="equity"
                      />
                    )}
                  </TabPanel>
                </Tabs>
              </TabPanel>
            )}
            <TabPanel>
              <Tabs>
                <TabList>
                  <Tab>{t("Crypto Risk Info")}</Tab>
                  <Tab>{t("Crypto Futures Risk Info")}</Tab>
                  <Tab>{t("FX Risk Info")}</Tab>
                  <Tab>{t("Equity Risk Info")}</Tab>
                </TabList>
                <TabPanel>
                  {isEdit && (
                    <CryptoRiskInfo
                      isEdit={isEdit}
                      isNew={isNew}
                      targetUserId={editData.id}
                      refetch={refetch}
                      onClose={onClose}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {isEdit && (
                    <CryptoFuturesRiskInfo
                      isEdit={isEdit}
                      isNew={isNew}
                      targetUserId={editData.id}
                      refetch={refetch}
                      onClose={onClose}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {isEdit && (
                    <FuturesRiskInfo
                      isEdit={isEdit}
                      isNew={isNew}
                      targetUserId={editData.id}
                      refetch={refetch}
                      onClose={onClose}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {isEdit && (
                    <StockRiskInfo
                      isEdit={isEdit}
                      isNew={isNew}
                      targetUserId={editData.id}
                      refetch={refetch}
                      onClose={onClose}
                    />
                  )}
                </TabPanel>
              </Tabs>
            </TabPanel>
            {isEdit &&
              (editData.role === "Informatic Broker (IB)" ||
                editData.role === "SubIB") && (
                <TabPanel>
                  <Leverage
                    isEdit={isEdit}
                    editData={editData}
                    selectChange={() => console.log("hi")}
                  />
                </TabPanel>
              )}
            <TabPanel>
              {isEdit && <ChangePassword isEdit={isEdit} editData={editData} />}
            </TabPanel>

            {isEdit &&
              (editData.role === "Informatic Broker (IB)" ||
                editData.role === "SubIB") && (
                <TabPanel>
                  <Tabs>
                    <TabList>
                      <Tab>{t("Crypto")}</Tab>
                      <Tab>{t("Forex")}</Tab>
                      <Tab>{t("Equity")}</Tab>
                    </TabList>
                    <TabPanel>
                      {isEdit && (
                        <Crypto
                          isEdit={isEdit}
                          isNew={isNew}
                          targetUserId={editData.id}
                          refetch={refetch}
                          onClose={onClose}
                        />
                      )}
                    </TabPanel>
                    <TabPanel>
                      {isEdit && (
                        <Forex
                          isEdit={isEdit}
                          isNew={isNew}
                          targetUserId={editData.id}
                          refetch={refetch}
                          onClose={onClose}
                        />
                      )}
                    </TabPanel>
                    <TabPanel>
                      {isEdit && (
                        <Equity
                          isEdit={isEdit}
                          isNew={isNew}
                          targetUserId={editData.id}
                          refetch={refetch}
                          onClose={onClose}
                        />
                      )}
                    </TabPanel>
                  </Tabs>
                </TabPanel>
              )}

            {isEdit &&
              (editData.role === "Informatic Broker (IB)" ||
                editData.role === "SubIB") && (
                <TabPanel>
                  <AssetBlockTabs isEdit={isEdit} editData={editData} />
                </TabPanel>
              )}
          </div>
        </div>
      </Tabs>
    </div>
  );
};
