import React, { useEffect, useState } from "react";
import { useGetPairsCommissionQuery } from "../../../redux/commissionApi";
import {
  useAddBulkSpreadMutation,
  useGetSpreadsQuery,
} from "../../../redux/spreadApi";
import CryptoSpreadsTable from "./CryptoSpreadsTable";
import TableLoader from "../../widgets/TableLoader";
import Button from "../../widgets/Button";
import { handleToast } from "../../utils/handleToast";
import { ValidateInputFields } from "../../validations/ValidateInputFields";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const CryptoSpreads = ({
  targetUserId,
  isEdit,
  isNew,
  refetch,
  onClose,
  editData,
}) => {
  const { t } = useTranslation();

  const { data: userSpreads = [], isLoading } = useGetSpreadsQuery({
    type: "crypto",
    userId: targetUserId,
  });

  const [addBulkSpread, { isLoading: addingCommission }] =
    useAddBulkSpreadMutation();

  const [cryptoCoinRates2, setCryptoCoinRates2] = useState([]);

  const [commissionRate, setCommissionRate] = useState({
    sell: { value: "", required: true },
    buy: { value: "", required: true },
  });

  const { data: cryptoPairs, isLoading: loadingCryptoCoins } =
    useGetPairsCommissionQuery({
      userId: targetUserId,
    });

  const [commissionBuyError, setCommissionBuyError] = useState();

  useEffect(() => {
    if (userSpreads.length > 0 && cryptoPairs) {
      const userCommMap = new Map();
      userSpreads.forEach((item) => {
        userCommMap.set(
          `${item.first_coin_symbol}_${item.second_coin_symbol}`,
          {
            id: item.pairId,
            symbol: item.first_coin_symbol + item.second_coin_symbol,
            spread_percentage: item.spread_percentage
              ? item.spread_percentage
              : 0,

            type: item.type,
          }
        );
      });

      const mergedArray = [];

      cryptoPairs.forEach((item) => {
        const key = `${item.first_coin_symbol}_${item.second_coin_symbol}`;

        if (userCommMap.has(key)) {
          mergedArray.push(userCommMap.get(key));
        } else {
          mergedArray.push({
            id: item.id,
            symbol: item.first_coin_symbol + item.second_coin_symbol,
            spread_percentage: 0,

            type: "Flat",
          });
        }
      });

      setCryptoCoinRates2(mergedArray);
    } else if (cryptoPairs && userSpreads && userSpreads.length === 0) {
      const mergedArray = [];

      cryptoPairs.forEach((item) => {
        mergedArray.push({
          id: item.id,
          symbol: item.first_coin_symbol + item.second_coin_symbol,
          spread_percentage: 0,
          type: "Flat",
        });
      });

      setCryptoCoinRates2(mergedArray);
    }
  }, [cryptoPairs, userSpreads]);

  const handleForAll = (type) => {
    const validationError = ValidateInputFields(
      type,
      commissionRate[type].value,
      commissionRate[type].required
    );

    if (validationError) {
      setCommissionBuyError(validationError);
      return;
    }
    if (Number(commissionRate[type].value) < 0) {
      setCommissionBuyError("Rate must be positive or zero");
      return;
    }

    if (Number(commissionRate[type].value) > 30) {
      setCommissionBuyError("Rate must be 30% or below");
      return;
    }

    if (userSpreads.length > 0) {
      const cryptoCoinRates = cryptoCoinRates2?.map((coin) => {
        return {
          ...coin,
          id: coin.pairId || coin.id,
          [`spread_percentage`]: commissionRate[type].value,
        };
      });

      setCryptoCoinRates2(cryptoCoinRates);
    } else if (cryptoPairs && userSpreads && userSpreads.length === 0) {
      const cryptoCoinRates = cryptoCoinRates2?.map((coin) => {
        return {
          ...coin,
          [`spread_percentage`]: commissionRate[type].value,
        };
      });
      setCryptoCoinRates2(cryptoCoinRates);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const handleUpdateOrAdd = async () => {
      const commissionBuyRates = cryptoCoinRates2
        .map((coin) => Number(coin.spread_percentage))
        .every((rate) => rate > -1);

      if (!commissionBuyRates) {
        toast.dismiss();
        toast.error("Commission rates must be positive or zero");
        return;
      }
      console.log({ spreads: cryptoCoinRates2, userId: targetUserId }, "III");
      // return;

      const mutationResult = await addBulkSpread({
        spreads: cryptoCoinRates2,
        userId: targetUserId,
        rateType: "",
        assetType: "crypto",
      });

      handleToast(t, mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
      }
    };

    handleUpdateOrAdd();
  };

  const handleEditCommissionRates = (data, type) => {
    console.log(data, type, "hiii", cryptoCoinRates2);
    setCryptoCoinRates2((prevCryptoData) =>
      prevCryptoData.map((crypto) =>
        crypto.id === data.id ? { ...crypto, [type]: data[type] } : crypto
      )
    );
  };

  console.log(cryptoCoinRates2, "cryptoCoinRates2");

  return (
    <div>
      {loadingCryptoCoins || isLoading ? (
        <TableLoader />
      ) : (
        <>
          <div className="row align-items-end pt-2 pb-2">
            <div className="col-12 ">
              <div className="form-group mb-md-0">
                <label className="fw-bold">{t("Enter Spread Amount")}</label>
                <div className="row">
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder={t("Spread Amount")}
                      name="commissionRate"
                      onChange={(e) => {
                        setCommissionRate((prev) => {
                          return {
                            ...prev,
                            buy: {
                              ...prev["buy"],
                              value: e.target.value,
                            },
                          };
                        });

                        const validationError = ValidateInputFields(
                          "buy",
                          e.target.value,
                          commissionRate["buy"].required
                        );
                        if (validationError) {
                          setCommissionBuyError(validationError);
                          return;
                        }
                        if (Number(e.target.value) < 0) {
                          setCommissionBuyError(
                            "Rate must be positive or zero"
                          );
                          return;
                        }

                        if (Number(e.target.value) > 30) {
                          setCommissionBuyError("Rate must be 30% or below");
                          return;
                        }

                        setCommissionBuyError("");
                      }}
                      value={commissionRate.buy?.value}
                    />
                    <span style={{ color: "red" }}>
                      {t(commissionBuyError)}
                    </span>
                    <div
                      className="col-6 mt-2"
                      onClick={() => handleForAll("buy")}
                    >
                      <div></div>
                      <Button className="btn btn1" variant="primary">
                        {t("Set Buy")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CryptoSpreadsTable
            data={cryptoCoinRates2}
            onEdit={handleEditCommissionRates}
          />
          {cryptoCoinRates2 && cryptoCoinRates2.length > 0 && (
            <Button
              className="btn btn1 mt-2"
              variant={"primary"}
              isLoading={addingCommission}
              onClick={handleSubmit}
            >
              {t("Save")}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default CryptoSpreads;
